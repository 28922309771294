:root {
  --primary: #AE562D;
  --theme-olive: #4C6C53;
  --theme-olive-light: #A6B6A9;
  --theme-light: #F2DDCB;
  --theme-gold: #A87813;
  --theme-gold-transparent: #a8791388;
  --theme-dark: #072124;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: Baginda;
  src: url("/src/static/font/BagindaScript.otf");
}

@font-face {
  font-family: BetterTogether;
  src: url("/src/static/font/Better\ Together\ Spaced.ttf");
}

@font-face {
  font-family: PatrickHand;
  src: url("/src/static/font/Itim-Regular.ttf");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.patrick-hand {
  font-family: PatrickHand;
}

.patrick-hand.huge {
  font-size: 20px !important;
}

.better-together {
  font-family: BetterTogether;
}

.baginda {
  font-family: Baginda;
  font-size: 38px;
  line-height: 0.8;
}

.baginda .big {
  font-size: 28px !important;
}


.better-together .nav-link {
  font-size: 28px;
}

.better-together .big {
  font-size: 28px !important;
}

.better-together .huge {
  font-size: 36px !important;
}

.better-together.huge {
  font-size: 36px !important;
}

.better-together .huge-2 {
  font-size: 52px !important;
}

.better-together.huge-2 {
  font-size: 52px !important;
}

.bg-imaged {
  background-image: url('../public/bg.jpg');
  background-repeat: repeat;
}

.bg-terra {
  background-color: var(--primary) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gold {
  background-color: var(--theme-gold) !important;
}

.bg-olive {
  background-color: var(--theme-olive) !important;
}

.bg-marine {
  background-color: var(--theme-dark) !important;
}

.text-terra {
  color: var(--primary);
}

.text-gold {
  color: var(--theme-gold) !important;
}

.text-sand {
  color: var(--theme-light) !important;

}

.text-gold-transparent {
  color: var(--theme-gold-transparent) !important;
}

.text-terra-light {
  color: var(--theme-light);
}

.text-marine {
  color: var(--theme-dark) !important;
}

.border-bottom-gold {
  border-bottom: solid 0.5px var(--theme-gold-transparent) !important;
}


.text-secondary {
  color: var(--theme-olive);
}

.text-secondary-light {
  color: var(--theme-olive-light) !important;
}

a.hover-terra:hover {
  color: var(--primary) !important;
}

.primary-btn {
  border: 1px solid var(--theme-gold) !important;
  color: var(--theme-gold);
  padding: 5px 15px !important;
  margin-left: 20px;
  border-radius: 50px;
  width: fit-content;
  cursor: pointer;
}

.primary-btn:hover {
  transition: all 0.3s;
  background-color: #a879134b;
  color: white !important;


}

.primary-btn:hover.nav-link {
  color: white !important;
}

.secondary-btn {
  border: none !important;
  color: var(--theme-gold);
  padding: 5px 15px !important;
  background-color: #ffffff8c !important;
  margin-left: 20px;
  border-radius: 50px;
}

.secondary-btn:hover {
  transition: all 0.3s;
  background-color: #ffffff44 !important;

}

.secondary-btn:hover.nav-link {
  color: white !important;
  transition: all 0.3s;

}

.nav-link {
  color: var(--theme-gold) !important;
}

.nav-link.active {
  color: var(--primary) !important;
  font-weight: 500;
}

.nav-link:hover {
  color: white !important;
}


.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border: 1px solid rgba(255, 255, 255);
}

.hero {
  height: 100% !important;
  width: 100% !important;
  background-image: url("../src/static/images/bg3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px;
}

@media screen and (max-width: 768px) {
  .hero {
    background-image: url("../src/static/images/bg3-mobile.jpg");
  }
}

.hero-filter {
  height: 100% !important;
  width: 100% !important;
  background-color: #5e342a71;
  border-radius: 40px;

}

.primary-btn.btn-lg {
  padding: 10px 20px !important;
  margin-left: 0px;
}

.primary-btn.white {
  border: 1px solid white;
  color: white;
}

.screen {
  min-height: calc(100vh - 75px)
}

.bg-sand {
  background-color: var(--theme-light) !important;
}

.irregular-shape {
  -webkit-clip-path: polygon(20% 10%, 85% 30%, 100% 100%, 0% 100%);
  clip-path: polygon(20% 10%, 85% 30%, 100% 100%, 0% 100%);
}

.my-container {
  width: 100%;
  height: 100%;
  border-radius: 40px !important;
  padding: 3% !important;
  padding-top: 150px !important;
}

.my-rounded {
  border-radius: 50px !important;

}

.cart-tag {
  background-color: var(--theme-light);
  height: 50px;
  width: 50%;
  border-radius: 40px;
}

.pointer {
  cursor: pointer !important;
}

.shadow {
  box-shadow: 3px 1px 15px 4px rgba(0, 0, 0, 0.26);
  border-radius: 50px;

}

.spaced-text{
  letter-spacing: 2.5px;
}